const excludesAPIName = [
  "OwnerId",
  "CreatedById",
  "CreatedDate",
  "LastModifiedById",
  "LastModifiedDate",
  "IsDeleted",
];
const excludesDataType = [
  "Image",
  "MultiImage",
  "Lookup",
  "MasterDetail",
  "AutoNumber",
  "Formula",
  "RollupSummary",
];
const filterField = (list) => {
  return list
    .filter((item) => {
      return !excludesAPIName.includes(item.APIName);
    })
    .filter((item) => {
      return !excludesDataType.includes(item.DataType);
    });
};
export default {
  condMap: (state) => state.condMap,
  customerFields: (state) => filterField(state.customerFields),
  carFields: (state) => filterField(state.carFields),
  customerBehaFields: (state) => filterField(state.customerBehaFields),
  carBehaFields: (state) => filterField(state.carBehaFields),
  relationFields: (state) => filterField(state.relationFields),
  allFields: (state) => {
    const list = filterField(state.customerFields)
      .concat(filterField(state.carFields))
      .concat(filterField(state.customerBehaFields))
      .concat(filterField(state.carBehaFields))
      .concat(filterField(state.relationFields));
    return list;
  },
  idUploadFields: (state) => filterField(state.idUploadFields),
  channelFields: (state) => state.channelFields,
  userFields: (state) => state.userFields,
  flowFields: (state) => state.flowFields,
  customerBehavior: (state) => state.customerBehavior,
  carBehavior: (state) => state.carBehavior,
  campaignStatusConfig: (state) => state.campaignStatusConfig,
  campaignStatusMapping: (state) => state.campaignStatusMapping,
  messageTemplate: (state) => state.messageTemplate,
  campaign: (state) => state.campaign,
  operatorMap: (state) => state.operatorMap,
  package: (state) => state.package,
  allOperator: (state) => state.allOperator,
  channelLabelMap: (state) => {
    const map = {};
    state.channelFields.forEach((item) => {
      map[item.APIName] = item.Name;
    });
    return map;
  },
  allChannels: (state) => state.allChannels,
  typesChannels: (state) => state.typesChannels,
  menuPermission: (state) => state.menuPermission, // 菜单列表权限
  userInfo: (state) => state.userInfo,
  queryVal: (state) => state.queryVal,
  cliquePointsAndRecharges: (state) =>
    filterField(state.cliquePointsAndRecharges), // 积分充值表-集团
  cliqueCardsAndVouchers: (state) => filterField(state.cliqueCardsAndVouchers), // 卡券信息表-集团
  dealerPointsAndRecharges: (state) =>
    filterField(state.dealerPointsAndRecharges), // 积分充值表-门店
  dealerCardsAndVouchers: (state) => filterField(state.dealerCardsAndVouchers), // 卡券信息表-门店
  channelCustomerRelation: (state) =>
    filterField(state.channelCustomerRelation || []), // 连接渠道
};
