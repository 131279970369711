export default {
  SET_META_DATA(state, payload) {
    if (Array.isArray(payload)) {
      payload.forEach((item) => {
        if (item.APIName === "Customer") {
          // 客户
          state.customerFields = item._Fields.filter(
            (item) => item.FieldGroup !== "Behavior"
          );
          state.customerBehaFields = item._Fields.filter(
            (item) => item.FieldGroup === "Behavior"
          );
        } else if (item.APIName === "Car") {
          // 车
          state.carFields = item._Fields.filter(
            (item) => item.FieldGroup !== "Behavior"
          );
          state.carBehaFields = item._Fields.filter(
            (item) => item.FieldGroup === "Behavior"
          );
        } else if (item.APIName === "CustomerCarRelation") {
          state.relationFields = item._Fields;
        } else if (item.APIName === "MessageChannel") {
          state.channelFields = item._Fields;
        } else if (item.APIName === "Flow") {
          state.flowFields = item._Fields;
        } else if (item.APIName === "CustomerBehavior") {
          state.customerBehavior = item._Fields;
        } else if (item.APIName === "CarBehavior") {
          state.carBehavior = item._Fields;
        } else if (item.APIName === "CampaignStatusConfig") {
          state.campaignStatusConfig = item._Fields;
        } else if (item.APIName === "CampaignStatusMapping") {
          state.campaignStatusMapping = item._Fields;
        } else if (item.APIName === "MessageTemplate") {
          // 消息模板元数据
          state.messageTemplate = item._Fields;
        } else if (item.APIName === "Campaign") {
          // 运营模板类型
          state.campaign = item._Fields;
        } else if (item.APIName === "Package") {
          // 机器学习模型类别
          state.package = item._Fields;
        } else if (item.APIName === "IDUpload_CID") {
          state.idUploadFields = item._Fields;
        }
        // 积分充值表-集团
        else if (item.APIName === "CliquePointsAndRecharges") {
          state.cliquePointsAndRecharges = item._Fields;
        }
        // 卡券信息表-集团
        else if (item.APIName === "CliqueCardsAndVouchers") {
          state.cliqueCardsAndVouchers = item._Fields;
        }
        // 积分充值表-门店
        else if (item.APIName === "DealerPointsAndRecharges") {
          state.dealerPointsAndRecharges = item._Fields;
        }
        // 卡券信息表-门店
        else if (item.APIName === "DealerCardsAndVouchers") {
          state.dealerCardsAndVouchers = item._Fields;
        } else if (item.APIName === "User") {
          state.userFields = item._Fields;
        }
        // 连接渠道
        else if (item.APIName === "ChannelCustomerRelation") {
          state.channelCustomerRelation = item._Fields;
        }
      });
    }
  },
  SET_TOP_CHANNELS(state, payload) {
    state.allChannels = payload.channels || [];
    state.typesChannels = payload.types || [];
  },
  SET_User_Info(state, payload) {
    // ListView：客户中心；Package：模型中心；Campaign：运营中心；MessageChannel： 渠道管理；  MessageTemplate：内容管理

    const getModel = (APIName) => {
      const ObjectList = payload.Permissions.ObjectList || [];
      const findObj = ObjectList.find((item) => {
        return item.APIName === APIName;
      });
      const permission = {
        show: false,
        create: false,
        edit: false,
        delete: false,
        originData: findObj,
      };
      if (findObj) {
        const BasePermissions = findObj.BasePermissions || [];
        BasePermissions.forEach((item) => {
          if (item.APIName === "PermissionsRead" && item.Value === 1) {
            permission.show = true;
          }
          if (item.APIName === "PermissionsCreate" && item.Value === 1) {
            permission.create = true;
          }
          if (item.APIName === "PermissionsDelete" && item.Value === 1) {
            permission.delete = true;
          }
          if (item.APIName === "PermissionsEdit" && item.Value === 1) {
            permission.edit = true;
          }
        });
      }

      return permission;
    };

    let exportPermission = false;
    let keiPermission = false;
    let combineCustomerPermission = false;
    let permissionsYiQiXiu = false;
    try {
      if (
        payload.Permissions.GeneralPermissions.PermissionsExportData.Value == 1
      ) {
        exportPermission = true;
      }
      if (
        payload.Permissions.GeneralPermissions.PermissionsAccessKPIReport
          .Value == 1
      ) {
        keiPermission = true;
      }
      if (
        payload.Permissions.GeneralPermissions.PermissionsCombineCustomer
          .Value == 1
      ) {
        combineCustomerPermission = true;
      }
      if (payload.PermissionSet.PermissionsYiQiXiu == 1) {
        permissionsYiQiXiu = true;
      }
    } catch (error) {
      console.log(error);
    }

    const obj = {
      系统设置: {
        originData: payload.PermissionSet,
        show: payload.PermissionSet.PermissionsViewSetup == "1" ? true : false,
        create: true,
      },
      客户中心: {
        ...getModel("ListView"),
        exportPermission,
      },
      标签管理: {
        ...getModel("Tags"),
      },
      模型中心: {
        ...getModel("Package"),
      },
      运营中心: {
        ...getModel("Campaign"),
      },
      渠道管理: {
        ...getModel("MessageChannel"),
      },
      内容管理: {
        ...getModel("MessageTemplate"),
        permissionsYiQiXiu: permissionsYiQiXiu,
      },
      用户价值报告: {
        keiPermission,
        combineCustomerPermission,
        show: keiPermission || combineCustomerPermission,
      },
      全局触达限制: {
        ...getModel("MessageChannelGlobalLimit"),
      }
    };
    state.menuPermission = obj;
    state.userInfo = payload;
  },
  SET_QUERY_VAL(state, queryVal) {
    state.queryVal = queryVal;
  },
};
