<template>
  <!--<svg
    width="142"
    height="36"
    viewBox="0 0 142 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.6734 35.2941C15.8946 35.749 14.9351 35.749 14.1563 35.2941L1.2589 27.7615C0.0550034 27.0584 -0.357478 25.5011 0.337588 24.2833C1.03266 23.0654 2.57207 22.6482 3.77596 23.3513L15.4148 30.1489L27.0537 23.3513C28.2576 22.6482 29.797 23.0654 30.4921 24.2833C31.1871 25.5011 30.7747 27.0584 29.5708 27.7615L16.6734 35.2941Z"
      fill="#44C5B8"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.6734 0.778141C15.8946 0.3233 14.9351 0.3233 14.1563 0.778141L1.2589 8.31075C0.0550034 9.01388 -0.357478 10.5711 0.337588 11.789C1.03266 13.0068 2.57207 13.4241 3.77596 12.721L15.4148 5.92338L27.0537 12.721C28.2576 13.4241 29.797 13.0068 30.4921 11.789C31.1871 10.5711 30.7747 9.01388 29.5708 8.31075L16.6734 0.778141Z"
      fill="#0483EC"
    />
    <path
      d="M0.337643 15.484C1.03271 14.2661 2.57212 13.8489 3.77602 14.552L15.4149 21.3496L27.0537 14.552C28.2576 13.8489 29.7971 14.2661 30.4921 15.484C31.1872 16.7018 30.7747 18.2591 29.5708 18.9622L16.6734 26.4948C15.8946 26.9497 14.9351 26.9497 14.1563 26.4948L1.25895 18.9622C0.0550591 18.2591 -0.357425 16.7018 0.337643 15.484Z"
      fill="#0483EC"
    />
    <ellipse
      cx="15.4149"
      cy="16.3145"
      rx="2.47399"
      ry="2.48684"
      fill="#44C5B8"
    />
    <path
      d="M96.8943 13.4215H96.925L94.259 25.9273C94.2467 25.9832 94.2344 26.039 94.2222 26.0949C94.0483 26.887 93.8741 27.6805 93.5086 28.3293C93.1344 29.0238 92.7279 29.5052 92.0645 29.9118C91.418 30.3352 90.5845 30.547 89.5638 30.547C89.1896 30.547 88.8068 30.5131 88.4156 30.4453C88.0073 30.3945 87.6841 30.3268 87.446 30.2421L87.4715 27.2438C87.9648 27.4132 88.4241 27.4979 88.8494 27.4979C89.3427 27.4979 89.768 27.3285 90.1252 26.9898C90.4824 26.651 90.7376 26.1174 90.8907 25.389L93.0161 15.2173L93.3778 13.4165H91.2795L91.8473 10.6498H93.9085L94.2047 9.14862C94.7108 6.61766 96.2659 5.52588 99.1047 5.52588C100.006 5.52588 100.808 5.67476 101.314 5.79882L100.796 8.29256C100.549 8.21812 100.092 8.14368 99.549 8.14368C98.4506 8.14368 97.8211 8.62754 97.6236 9.58285L97.4014 10.6498H100.203L99.6231 13.4165H96.8954L96.8943 13.4215Z"
      fill="#26262C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M51.0997 23.8629H47.3599L46.9155 19.595H40.6455L38.3251 23.8629H34.3385L44.41 5.96011H48.804L51.0997 23.8629ZM45.9528 9.59525H45.8664L42.0773 16.7043H46.6934L45.9528 9.59525Z"
      fill="#26262C"
    />
    <path
      d="M63.4126 10.5258H66.9425L64.1284 23.8629H60.7342L61.1662 21.8158H61.0922C60.3763 23.1433 58.7594 24.1606 56.834 24.1606C54.3038 24.1606 52.7239 22.5726 52.7239 20.3146C52.7239 19.7687 52.7857 19.0615 52.8967 18.5404L54.5753 10.5258H58.1053L56.5254 17.9573C56.4637 18.3047 56.4144 18.7389 56.4144 19.0243C56.4144 20.389 57.1673 21.0962 58.6113 21.0962C60.2035 21.0962 61.4007 19.992 61.771 18.3047L63.4126 10.5258Z"
      fill="#26262C"
    />
    <path
      d="M72.1832 24.0118C72.788 24.0118 73.4298 23.9497 73.8371 23.8629L74.3925 21.1955C74.1827 21.2451 73.9605 21.2575 73.5038 21.2575C72.4424 21.2575 71.887 20.9225 71.887 20.0913C71.887 19.8679 71.924 19.5454 71.9857 19.2724L73.2323 13.5282H75.9353L76.5031 10.7491H73.7877L74.4789 7.51094H70.9489L70.2824 10.7491H68.3076L67.7275 13.5282H69.7023L68.357 19.8059C68.2459 20.327 68.2212 20.7984 68.2212 21.1334C68.2212 23.0565 69.7764 24.0118 72.1832 24.0118Z"
      fill="#26262C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M76.3994 18.1806C76.3994 13.615 79.5097 10.228 83.7679 10.228C87.3719 10.228 89.754 12.6225 89.754 16.1956C89.754 20.7612 86.6437 24.1606 82.3855 24.1606C78.7815 24.1606 76.3994 21.7662 76.3994 18.1806ZM79.9664 18.1062C79.9664 19.9796 80.9661 21.2327 82.4966 21.2327C84.6072 21.2327 86.1623 19.0863 86.1623 16.2824C86.1623 14.3966 85.1749 13.156 83.6445 13.156C81.5215 13.156 79.9664 15.2775 79.9664 18.1062Z"
      fill="#26262C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M99.4947 18.1806C99.4947 13.615 102.605 10.228 106.863 10.228C110.467 10.228 112.849 12.6225 112.849 16.1956C112.849 20.7612 109.739 24.1606 105.481 24.1606C101.877 24.1606 99.4947 21.7662 99.4947 18.1806ZM103.062 18.1062C103.062 19.9796 104.061 21.2327 105.592 21.2327C107.703 21.2327 109.258 19.0863 109.258 16.2824C109.258 14.3966 108.27 13.156 106.74 13.156C104.617 13.156 103.062 15.2775 103.062 18.1062Z"
      fill="#26262C"
    />
    <path
      d="M113.276 23.8629H116.806L118.337 16.6422C118.794 14.5827 119.929 13.5158 121.817 13.5158C122.533 13.5158 123.187 13.7019 123.644 13.9128L124.335 10.6622C123.928 10.4637 123.36 10.3272 122.657 10.3272C121.114 10.3272 119.917 11.1585 119.213 12.3867H119.139L119.509 10.5258H116.103L113.276 23.8629Z"
      fill="#26262C"
    />
    <path
      d="M130.983 23.8629C130.576 23.9497 129.934 24.0118 129.329 24.0118C126.922 24.0118 125.367 23.0565 125.367 21.1334C125.367 20.7984 125.392 20.327 125.503 19.8059L126.848 13.5282H124.873L125.454 10.7491H127.428L128.095 7.51094H131.625L130.934 10.7491H133.649L133.081 13.5282H130.378L129.132 19.2724C129.07 19.5454 129.033 19.8679 129.033 20.0913C129.033 20.9225 129.588 21.2575 130.65 21.2575C131.106 21.2575 131.329 21.2451 131.538 21.1955L130.983 23.8629Z"
      fill="#26262C"
    />
  </svg>-->
  <img src="../assets/logo.png" :style="`height: ${height}px;`" />
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 80,
    },
  },
};
</script>
