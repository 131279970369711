import api from "../Axios";

// 创建消息渠道
export const apiAddChannel = (params) => api.post(`/message-channel`, params);

// 获取消息渠道列表
export const apiGetChannelList = () => api.get(`/message-channels`);

// 获取消息渠道列表
export const apiGetChannelListByType = (type, params) =>
  api.get(`/message-channels/${type}`, { params });

// 获取消息渠道详情
export const apiGetChannelDetail = (id) => api.get(`/message-channel/${id}`);

// 更新消息渠道详情
export const apiUpdateChannel = (id, params) =>
  api.put(`/message-channel/${id}`, params);

// 获取消息渠道列表
export const apiDeleteChannel = (id) => api.delete(`/message-channel/${id}`);

// 获取全局触达限制配置
export const apiGetGlobalLimit = () => api.get(`/message-channel/global-limit`);

//编辑全局渠道限制
export const apiUpdateGlobalLimit = (data) => api.post(`/message-channel/global-limit`, data);
