<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="exist"
    title="修改密码"
    width="500px"
    :show-close="false"
    :modal-append-to-body="false"
    :append-to-body="true"
  >
    <el-form
      :model="formData"
      :rules="formRule"
      ref="form"
      class="edit-form"
      size="small"
    >
      <el-form-item
        :label-width="LabelWidth"
        label="原密码"
        prop="current_password"
      >
        <el-input
          v-model="formData.current_password"
          placeholder="请输入密码"
          type="password"
          style="width: 240px"
        ></el-input>
      </el-form-item>
      <el-form-item :label-width="LabelWidth" label="新密码" prop="password">
        <el-input
          v-model="formData.password"
          placeholder="请输入新密码"
          type="password"
          style="width: 240px"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label-width="LabelWidth"
        label="确认新密码"
        prop="confirmed_password"
      >
        <el-input
          v-model="formData.confirmed_password"
          placeholder="请再次输入新密码"
          type="password"
          style="width: 240px"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose" type="text" :disabled="saving"
        >取 消</el-button
      >
      <el-button type="primary" @click="handleSave" round v-loading="saving"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { apiEditUserPwd } from "@/api/common";
import { TOKEN_KEY } from "@/common/constants";
import Cookies from "js-cookie";

export default {
  components: {},
  props: {
    exist: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.formData.confirmed_password !== "") {
          this.$refs.form.validateField("confirmed_password");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formData.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      saving: false,
      LabelWidth: "130px",
      formData: {
        current_password: "", // 原密码
        password: "", // 新密码
        confirmed_password: "", // 确认新密码
      },
      formRule: {
        current_password: {
          required: true,
          message: "请输入原密码",
          trigger: "blur",
        },
        password: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
          { validator: validatePass, trigger: "blur" },
        ],
        confirmed_password: [
          {
            required: true,
            message: "请再次输入新密码",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleClose() {
      this.$emit("update", false);
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm();
        }
      });
    },
    submitForm() {
      this.saving = true;
      const param = {
        ...this.formData,
      };

      apiEditUserPwd(param)
        .then((res) => {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.$emit("success", res);
          Cookies.remove(TOKEN_KEY);
          location.href = "/login";
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>
