import Cookies from "js-cookie";
import { TOKEN_KEY } from "@/common/constants";

export default {
  SET_USERINFO(state, payload) {
    state.userInfo = payload;
    sessionStorage.setItem("userInfo", JSON.stringify(payload));
  },
  SET_TOKEN(state, payload) {
    state.access_token = payload.access_token;
    state.refresh_token = payload.refresh_token;
    Cookies.set(TOKEN_KEY, payload.access_token);
  },
  DELETE_TOKEN() {
    Cookies.remove(TOKEN_KEY);
    if (location.pathname.indexOf("login") < 0) {
      location.href = "/login";
    }
  },
  SET_SHOW_CHANGE_PWD(state) {
    state.showChangePwd = !state.showChangePwd
  }
};
