import Vue from "vue";
import Cookies from "js-cookie";
import ElementUI from "element-ui";
import Vcharts from "v-charts";
import ECharts from "vue-echarts/components/ECharts";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { TOKEN_KEY } from "@/common/constants";
import "@/directives/selectScrollMore";
import "element-ui/lib/theme-chalk/index.css";
import "@/style/common.less";
import '@/icons';     

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Vcharts);
// 全局注册chart组件
Vue.component("chart", ECharts);

//给div 绑 resize 事件 监听宽高变化 用于echart resize
Vue.directive("resize", {
  bind(el, binding) {
    let width = "",
      height = "";
    function get() {
      const style = document.defaultView.getComputedStyle(el);
      if (width !== style.width || height !== style.height) {
        binding.value({ width, height });
      }
      width = style.width;
      height = style.height;
    }

    el.__vueReize__ = setInterval(get, 200);
  },
  unbind(el) {
    clearInterval(el.__vueReize__);
  },
});

(async () => {
  try {
    if (Cookies.get(TOKEN_KEY)) {
      await store.dispatch("common/getUsersCurrentInfo");
    }

    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  } catch (error) {
    console.log(error);
  }
})();
