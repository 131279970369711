import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";
import { TOKEN_KEY } from "@/common/constants";
import store from "@/store/index";

Vue.use(VueRouter);

const NoPermission = () => import("../views/NoPermission.vue");
const noPage = () => import("../views/404.vue");

const routes = [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/index.vue"),
    meta: {
      auth: false,
      title: "用户登陆",
      aloneLayout: true,
    },
  },
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/group/list",
    name: "group-list",
    component: () =>
      import(/* webpackChunkName: "group" */ "../views/group/group-list.vue"),
    meta: {
      title: "分群和用户画像",
      parentName: "客户中心",
    },
  },
  {
    path: "/group/detail/:id",
    name: "group-detail",
    component: () =>
      import(/* webpackChunkName: "group" */ "../views/group/group-detail.vue"),
    meta: {
      title: "分群和用户画像",
      parentName: "客户中心",
    },
  },
  {
    path: "/customer/info/:id",
    name: "customer-info",
    component: () =>
      import(
        /* webpackChunkName: "group" */ "../views/group/customer-info.vue"
      ),
    meta: {
      title: "客户信息",
      parentName: "客户中心",
    },
  },
  {
    path: "/car/info/:id",
    name: "car-info",
    component: () =>
      import(/* webpackChunkName: "group" */ "../views/group/car-info.vue"),
    meta: {
      title: "车辆信息",
      parentName: "客户中心",
    },
  },
  {
    path: "/plan/list",
    name: "plan-list",
    component: () =>
      import(/* webpackChunkName: "plan" */ "../views/plan/plan-list.vue"),
    meta: {
      title: "运营计划",
      parentName: "运营中心",
    },
  },
  {
    path: "/plan/detail/:id",
    name: "plan-detail",
    component: () =>
      import(/* webpackChunkName: "group" */ "../views/plan/plan-detail.vue"),
    meta: {
      title: "企微员工执行结果分析",
      parentName: "运营计划",
    },
  },
  {
    path: "/plan/report/:id",
    name: "plan-report",
    component: () =>
      import(/* webpackChunkName: "group" */ "../views/plan/plan-report.vue"),
    meta: {
      title: "运营计划画像",
      parentName: "运营中心",
    },
  },
  {
    path: "/message/list",
    name: "message-list",
    component: () =>
      import(/* webpackChunkName: "message" */ "../views/message/list.vue"),
    meta: {
      title: "短信",
      parentName: "渠道管理",
    },
  },
  {
    path: "/gzhao/list",
    name: "gzhao-list",
    component: () =>
      import(/* webpackChunkName: "message" */ "../views/gzhao/list.vue"),
    meta: {
      title: "微信公众号",
      parentName: "渠道管理",
    },
  },
  {
    path: "/wechatapplet/list",
    name: "wechatappletlist",
    component: () =>
      import(
        /* webpackChunkName: "message" */ "../views/wechat-applet/list.vue"
      ),
    meta: {
      title: "微信小程序",
      parentName: "渠道管理",
    },
  },
  {
    path: "/companywx/list",
    name: "companywxlist",
    component: () =>
      import(/* webpackChunkName: "message" */ "../views/company-wx/list.vue"),
    meta: {
      title: "企业微信",
      parentName: "渠道管理",
    },
  },
  // {
  //   path: "/miniprogram/list",
  //   name: "miniprogram",
  //   component: () =>
  //     import(/* webpackChunkName: "message" */ "../views/miniprogram/list.vue"),
  //   meta: {
  //     title: "微信小程序",
  //     parentName: "渠道管理",
  //   },
  // },
  {
    path: "/app/list",
    name: "app",
    component: () =>
      import(/* webpackChunkName: "message" */ "../views/app/list.vue"),
    meta: {
      title: "App",
      parentName: "渠道管理",
    },
  },
  {
    path: "/template/eqxiu",
    name: "template-eqxiu",
    component: () =>
      import(/* webpackChunkName: "template" */ "../views/template/eqxiu.vue"),
    meta: {
      title: "易秀",
      parentName: "易秀",
    },
  },
  {
    path: "/template/list",
    name: "template-list",
    component: () =>
      import(/* webpackChunkName: "template" */ "../views/template/list.vue"),
    meta: {
      title: "内容管理",
      parentName: "内容管理",
    },
  },
  // {
  //   path: "/tag/index",
  //   name: "tag-index",
  //   component: () =>
  //     import(/* webpackChunkName: "tag" */ "../views/tag/index.vue"),
  //   meta: {
  //     title: "标签管理",
  //     parentName: "客户中心",
  //   },
  // },
  {
    path: "/tag/customerLabel/index",
    name: "tag-customerLabel-index",
    component: () =>
      import(
        /* webpackChunkName: "tag" */ "../views/tag/customerLabel/index.vue"
      ),
    meta: {
      title: "客户标签",
      parentName: "客户标签",
    },
  },
  {
    path: "/tag/carLabel/index",
    name: "tag-carLabel-index",
    component: () =>
      import(/* webpackChunkName: "tag" */ "../views/tag/carLabel/index.vue"),
    meta: {
      title: "车辆标签",
      parentName: "车辆标签",
    },
  },
  {
    path: "/user/index",
    name: "user-index",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/user/index.vue"),
    meta: {
      title: "用户管理",
      parentName: "系统设置",
    },
  },
  {
    path: "/role/index",
    name: "role-index",
    component: () =>
      import(/* webpackChunkName: "role" */ "../views/role/index.vue"),
    meta: {
      title: "角色管理",
      parentName: "系统设置",
    },
  },
  {
    path: "/auth/index",
    name: "auth-index",
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/auth/index.vue"),
    meta: {
      title: "权限集",
      parentName: "系统设置",
    },
  },
  {
    path: "/global/limit",
    name: "global-limit",
    component: () =>
      import(/* webpackChunkName: "global" */ "../views/global/index.vue"),
    meta: {
      title: "全局触达限制",
      parentName: "运营中心",
    },
  },
  {
    path: "/plan-template/list",
    name: "plan-template-list",
    component: () =>
      import(
        /* webpackChunkName: "planTemplate" */ "../views/plan-template/list.vue"
      ),
    meta: {
      title: "活动模板库",
      parentName: "运营中心",
    },
  },
  {
    path: "/model-center-list",
    name: "model-center-list",
    component: () =>
      import(
        /* webpackChunkName: "modelCenter" */ "../views/model-center/index.vue"
      ),
    meta: {
      title: "模型中心 ",
      parentName: "模型中心",
    },
  },
  {
    path: "/model-center-yuceping",
    name: "model-center-yuceping",
    component: () =>
      import(
        /* webpackChunkName: "yuceping" */ "../views/model-center/yuceping.vue"
      ),
    meta: {
      title: "预测评分类模型",
      menuRouter: "/model-center-list",
      parentName: "模型中心",
    },
  },
  {
    path: "/share-setting",
    name: "share-setting",
    component: () =>
      import(
        /* webpackChunkName: "yuceping" */ "../views/share-setting/index.vue"
      ),
    meta: {
      title: "共享设置",
      parentName: "系统设置",
    },
  },
  {
    path: "/query",
    name: "query",
    component: () =>
      import(
        /* webpackChunkName: "query" */
        "../views/query/index.vue"
      ),
    meta: {
      title: "共享设置",
      parentName: "系统设置",
    },
  },
  {
    path: "/cdpbi/overall-analysis",
    name: "overall-analysis",
    component: () =>
      import(
        /* webpackChunkName: "cdpbi" */
        "../views/cdpbi/overall-analysis.vue"
      ),
    meta: {
      title: "整体分析",
      parentName: "用户价值报告",
    },
  },
  {
    path: "/cdpbi/trend-analysis",
    name: "trend-analysis",
    component: () =>
      import(
        /* webpackChunkName: "cdpbi" */
        "../views/cdpbi/trend-analysis.vue"
      ),
    meta: {
      title: "趋势分析",
      parentName: "用户价值报告",
    },
  },
  {
    path: "/cdpbi/cus-merge",
    name: "cus-merge",
    component: () =>
      import(
        /* webpackChunkName: "cdpbi" */
        "../views/cdpbi/cus-merge.vue"
      ),
    meta: {
      title: "客户合并",
      parentName: "用户价值报告",
    },
  },
  {
    path: "/nopermission", // 无权限访问的提示页面
    name: "NoPermission",
    component: NoPermission,
  },
  {
    path: "/404", // 404提示页面
    name: "404",
    component: noPage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const { common } = store.state;
  /**
   * 登陆流程
   * 1. 跳到AC去登陆，登陆成功后AC会重定向到当前地址，并在url参数中返回随机生成的code
   * 2. 前端拿到code后，带上code请求后端
   * 3. 后端使用code去AC换取accessToken，返回给前端
   * 4. 接口调用时，后端根据cookie中的accessToken判断用户是否登陆或超时
   */
  const token = Cookies.get(TOKEN_KEY);
  // 返回 true 有权限访问
  const permission = (to) => {
    let bool = false;
    if (common.menuPermission) {
      if (common.menuPermission[to.meta.parentName]) {
        bool = common.menuPermission[to.meta.parentName].show;
      }
    }

    // 没有这个值，默认都有权限
    if (!to.meta.parentName || !common.menuPermission[to.meta.parentName]) {
      bool = true;
    }

    // 搜索页面不用权限配合
    if (to.path.startsWith("/query")) {
      bool = true;
    }

    return bool;
  };

  if (["/nopermission"].includes(to.path)) {
    return next();
  }

  if (to.meta.auth === false) {
    // 不需要登录
    next();
  } else if (!token) {
    next({ name: "login" });
  } else if (!permission(to)) {
    if (!["/nopermission"].includes(from.path)) {
      next({ name: "NoPermission" });
    }
  } else if (to.name === "home") {
    next({ name: "group-list" });
  } else {
    next();
  }
});

export default router;
