import api from "../Axios";

export const apiGetMetaData = (params) => api.get("/metadata", { params });

//获取当前登录用户的基本信息
export const apiGetUsersCurrentInfo = () => api.get("/users/current");

// 点击日志上报。 接口文档：https://yin2020.coding.net/p/zencrm/wiki/387#user-content-%E6%8E%A5%E5%8F%A3%E8%AF%B4%E6%98%8E
export const apiReportClick = (params) => api.post(`/report/click`, params);

// 更改用户密码
export const apiEditUserPwd = (params) => api.put(`/user/password`, params);
