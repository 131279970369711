import api from "../Axios";

export const apiGetToken = (params) => api.post("/token", params);

export const apiGetImgCode = () => api.get("/imgCode");

export const apiRefreshToken = (params) => api.post("/refresh-token", params);

export const apiDeleteToken = (params) => api.delete("/token", params);

// 获取用户列表
export const apiGetUserList = (params) => api.get("/users", { params });

// 创建用户
export const apiCreateUser = (params) => api.post("/user", params);

// 删除用户
export const apiDeleteUser = (id) => api.delete(`/user/${id}`);

// 更改用户
export const apiEditUser = (id, params) => api.put(`/user/${id}`, params);

// 更改用户密码
export const apiEditUserPwd = (id, params) =>
  api.put(`/user/pwd/${id}`, params);

// 获取角色列表
export const apiGetRolesList = (params) => api.get("/roles", { params });

// 创建角色
export const apiCreateRole = (params) => api.post("/role", params);

// 更改角色
export const apiEditRole = (id, params) => api.put(`/role/${id}`, params);

// 删除角色
export const apiDeleteRole = (id) => api.delete(`/role/${id}`);

// 获取权限列表
export const apiGetPermissionsList = (params) =>
  api.get("/permissions", { params });

// 获取权限 0为初始化
export const apiGetPermissionDetail = (id) => api.get(`/permission/${id}`);

// 创建 / 编辑权限
export const apiCreatePermissions = (id, params) =>
  api.post(`/permission/${id}`, params);

// 删除权限列表
export const apiDeletePermissions = (id) => api.delete(`/permission/${id}`);

// 共享设置首页 -获取共享列表
export const apiGetShareList = (params) => api.get("/share", { params });

// 删除共享设置
export const apiDeleteShare = (id) => api.delete(`/share/${id}`);

// 保存组织范围内共享设置
export const apiEditOrgScope = (params) => api.put(`/share/orgScope`, params);

// 新增共享规则
export const apiCreateShareRules = (params) => api.post(`/share`, params);

// 获取共享规则
export const apiGetShareDetail = (id) => api.get(`/share/${id}`);

// Customer：客户中心；Package：模型中心；Campaign：运营中心；MessageChannel： 渠道管理；  MessageTemplate：内容管理
// 获取客户中心权限
export const apiGetCustomerPermission = (id, params) =>
  api.get(`/permissions/ListView/${id}/detect`, { params });

// 获取模型中心权限
export const apiGetModelCenterPermission = (id, params) =>
  api.get(`/permissions/Package/${id}/detect`, { params });

// 获取运营中心权限
export const apiGetCampaignPermission = (id, params) =>
  api.get(`/permissions/Campaign/${id}/detect`, { params });

// 获取渠道管理权限
export const apiGetMessageChannelPermission = (id, params) =>
  api.get(`/permissions/MessageChannel/${id}/detect`, { params });

// 获取内容管理权限
export const apiGetMessageTemplatePermission = (id, params) =>
  api.get(`/permissions/MessageTemplate/${id}/detect`, { params });

// 启用/禁用用户
export const apiActivateOrDeactiveUser = (id, params) =>
  api.put(`/user/activateOrDeactiveUser/${id}`, params);

// 查看用户详情
export const apiGetUsersDetail = (id) => api.get(`/users/${id}`);
