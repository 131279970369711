<template>
  <div class="page-header-context">
    <div class="logo">
      <router-link to="/" style="width: 300px">
        <logo :height="38" style="width: 158px; margin: 7px;"></logo>
      </router-link>
      <el-input
        placeholder="搜索..."
        prefix-icon="el-icon-search"
        @blur="queryBlur"
        @keyup.enter.native="queryBlur"
        v-model="queryVal"
      >
      </el-input>
    </div>
    <div class="right">
      <el-dropdown class="profile" @command="handleProfile">
        <span class="el-dropdown-link">
          <span>{{ userInfo && userInfo.Name }}</span>
          <div class="avatar-img">
            {{ userInfo && userInfo.Name && userInfo.Name[0] }}
          </div>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item disabled>{{
            userInfo.nick_name || userInfo.user_name
          }}</el-dropdown-item> -->
          <el-dropdown-item command="updatePwd">修改密码</el-dropdown-item>
          <el-dropdown-item command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <pwdDialog
      :exist="existDrawer"
      @update="handleCancel"
      @success="handleSaved"
      v-if="existDrawer"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import logo from "@/components/logo";
import pwdDialog from "./pwd-dialog";
// import { apiDeleteToken } from "@/api/auth";
export default {
  components: {
    logo,
    pwdDialog,
  },
  computed: {
    ...mapGetters({
      userInfo: "common/userInfo",
    }),
  },
  data() {
    return {
      queryVal: "",
      existDrawer: false,
    };
  },
  watch: {
    '$store.state.auth.showChangePwd': {
      handler(val) {
        if (val) {
          this.updatePwd();
          this.$stores.commit('auth/SET_SHOW_CHANGE_PWD')
        }
      }
    }
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
      setQueryVal: "common/setQueryVal",
    }),
    handleProfile(cmd) {
      switch (cmd) {
        case "updatePwd":
          this.updatePwd();
          break;
        case "logout":
          this.logout();
          break;
        default:
          console.log("无效的命令");
      }
    },
    queryBlur() {
      const queryVal = this.queryVal.replace(/(^\s*)|(\s*$)/g, "").toString();
      if (queryVal !== "") {
        this.setQueryVal(queryVal);
        if (this.$route.path !== "/query") {
          this.$router.push("/query");
        }
      }
    },
    updatePwd() {
      this.existDrawer = true;
    },
    handleSaved() {
      this.existDrawer = false;
    },
    handleCancel() {
      this.existDrawer = false;
    },
    // logout() {
    //   apiDeleteToken().then(() => {
    //     this.$router.push("/login");
    //   });
    // },
  },
};
</script>

<style lang="less">
.page-header-context {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 78px;
  padding: 0 22px 0 20px;
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.04);
  .logo {
    display: flex;
    .el-input {
      margin-left: 30px;
      line-height: 55px;
    }
    input {
      width: 420px;
      border: 0;
      border-bottom: 1px solid rgba(183, 182, 185, 1);
      border-radius: 0;
    }
  }
  .navbar-header {
    .el-button {
      i {
        font-size: 22px;
        color: #353d47;
      }
    }

    .el-breadcrumb {
      display: inline-block;
      vertical-align: text-top;
    }
  }
  .switch-team {
    float: right;
    button {
      padding: 22px 0;
      color: #333;
    }
  }
  .split {
    float: right;
    padding: 20px 10px;
    span {
      display: block;
      height: 20px;
      border-right: 1px solid #ccc;
    }
  }
  .right {
    line-height: 36px;
    .tool-icon {
      font-size: 19px;
      color: #747378;
    }
    .el-icon-s-tools {
      margin: 0 22px;
    }
    .el-divider {
      height: 36px;
      margin: 0 24px 0 17px;
    }
  }
  .profile {
    float: right;
    .avatar-img {
      display: inline-block;
      vertical-align: middle;
      margin-left: 20px;
      width: 36px;
      height: 36px;
      line-height: 36px;
      border-radius: 50%;
      text-align: center;
      background: #0483ec;
      color: #fff;
    }
  }
}
</style>
