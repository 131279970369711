import * as services from "@/api/auth";
import { MessageBox } from 'element-ui';
export default {
  async getToken({ commit }, params) {
    return services.apiGetToken(params).then((res) => {
      if (res.need_update_password_tip) {
        MessageBox.confirm('系统检测到您长时间未修改密码，为了账号安全建议您修改密码！', '系统提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '现在就改',
          cancelButtonText: '以后再改'
        }).then(() => {
          commit('SET_SHOW_CHANGE_PWD')
        })
      }
      return commit("SET_TOKEN", res);
    });
  },
  async refreshToken({ commit }, params) {
    return services.apiGetToken(params).then((res) => {
      return commit("REFRESH_TOKEN", res);
    });
  },
  async logout({ commit }) {
    return services.apiDeleteToken().then(() => {
      return commit("DELETE_TOKEN");
    });
  },
};
