import { apiGetMetaData, apiGetUsersCurrentInfo } from "@/api/common";
import { apiGetChannelList } from "@/api/channel";
export default {
  async getMetaData({ commit }, params) {
    return apiGetMetaData(params).then((res) => {
      commit("SET_META_DATA", res);
    });
  },
  async getAllChannels({ commit }, params) {
    return apiGetChannelList(params).then((res) => {
      commit("SET_TOP_CHANNELS", res);
    });
  },
  async getUsersCurrentInfo({ commit }, params) {
    return apiGetUsersCurrentInfo(params).then((res) => {
      commit("SET_User_Info", res);
    });
  },
  setQueryVal({ commit }, val) {
    commit("SET_QUERY_VAL", val);
  },
};
