// select滚动加载更多

import Vue from "vue";

Vue.directive("loadmore", {
  bind(el, binding) {
    // 获取element-ui定义好的scroll盒子
    const SELECTWRAP_DOM = el.querySelector(
      ".el-select-dropdown .el-select-dropdown__wrap"
    );
    SELECTWRAP_DOM.addEventListener("scroll", function () {
      /**
       * scrollHeight（文档内容实际高度，包括超出视窗的溢出部分）、
         scrollTop（滚动条滚动距离）、
         clientHeight（窗口可视范围高度）
       */
      const CONDITION =
        this.clientHeight + this.scrollTop + 20 >= this.scrollHeight;
      if (CONDITION) {
        binding.value();
      }
    });
  },
});
