<template>
  <div class="aside-menu">
    <div>
      <el-menu
        text-color="#747378"
        active-text-color="#0375D3"
        :default-active="activeIndex"
        :router="true"
      >
        <!-- <el-menu-item index="/">
          <i class="iconfont icon-home"></i>
          <span class="level-1">首页</span>
        </el-menu-item> -->
        <el-submenu
          index="1"
          v-if="menuPermission.客户中心 && menuPermission.客户中心.show"
        >
          <template slot="title">
            <i class="iconfont icon-user-doublekx"></i>
            <span class="level-1">客户中心</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/group/list">分群和群画像</el-menu-item>
            <el-menu-item
              index="/group/list?status=add"
              v-if="menuPermission.客户中心 && menuPermission.客户中心.create"
              >创建客户分群</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>

        <!-- <el-menu-item
          index="/model-center-list"
          v-if="menuPermission.模型中心 && menuPermission.模型中心.show"
        >
          <i class="iconfont icon-model-center"></i>
          <span class="level-1">模型中心 </span>
        </el-menu-item> -->
        <el-submenu
          index="4"
          v-if="menuPermission.内容管理 && menuPermission.内容管理.show"
        >
          <template slot="title">
            <i class="iconfont icon-neirong"></i>
            <span class="level-1">内容管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              index="/template/eqxiu"
              v-if="
                menuPermission.内容管理 &&
                menuPermission.内容管理.permissionsYiQiXiu
              "
              >易企秀</el-menu-item
            >
            <el-menu-item index="/template/list">内容管理</el-menu-item>
            <el-menu-item
              index="/template/list?status=add"
              v-if="menuPermission.内容管理 && menuPermission.内容管理.create"
              >新增内容</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
        <el-submenu
          index="2"
          v-if="menuPermission.运营中心 && menuPermission.运营中心.show"
        >
          <template slot="title">
            <i class="iconfont icon-yunying"></i>
            <span class="level-1">运营中心</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/plan/list">运营计划</el-menu-item>
            <el-menu-item
              index="/plan/list?status=add"
              v-if="menuPermission.运营中心 && menuPermission.运营中心.create"
              >创建运营计划</el-menu-item
            >
            <!-- <el-menu-item index="/plan-template/list">活动模板库</el-menu-item> -->
            <el-menu-item index="/global/limit" v-if="menuPermission.全局触达限制 && menuPermission.全局触达限制.show">全局触达限制</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu
          index="3"
          v-if="menuPermission.渠道管理 && menuPermission.渠道管理.show"
        >
          <template slot="title">
            <i class="iconfont icon-plan"></i>
            <span class="level-1">渠道管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/message/list">短信</el-menu-item>
            <el-menu-item index="/gzhao/list">微信公众号</el-menu-item>
            <el-menu-item index="/companywx/list">企业微信</el-menu-item>
            <!-- <el-menu-item index="/wechatapplet/list">微信小程序</el-menu-item> -->
            <!-- <el-menu-item index="/app/list">App</el-menu-item> -->
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item
          index="/model-center-list"
          v-if="menuPermission.模型中心 && menuPermission.模型中心.show"
        >
          <i class="iconfont icon-model-center"></i>
          <span class="level-1">模型中心 </span>
        </el-menu-item>
        <el-submenu
          index="5"
          v-if="menuPermission.用户价值报告 && menuPermission.用户价值报告.show"
          class="cdpbiMenu"
        >
          <template slot="title">
            <i class="iconfont icon-kpi-report"></i>
            <span class="level-1">用户价值报告</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              index="/cdpbi/overall-analysis"
              v-if="
                menuPermission.用户价值报告 &&
                menuPermission.用户价值报告.keiPermission
              "
              >整体分析</el-menu-item
            >
            <el-menu-item
              index="/cdpbi/trend-analysis"
              v-if="
                menuPermission.用户价值报告 &&
                menuPermission.用户价值报告.keiPermission
              "
              >趋势分析</el-menu-item
            >
            <el-menu-item
              index="/cdpbi/cus-merge"
              v-if="
                menuPermission.用户价值报告 &&
                menuPermission.用户价值报告.combineCustomerPermission
              "
              >客户合并</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
        <el-submenu
          index="7"
          v-if="menuPermission.标签管理 && menuPermission.标签管理.show"
        >
          <template slot="title">
            <i class="iconfont icon-tag"></i>
            <span class="level-1">标签管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/tag/customerLabel/index">客户标签</el-menu-item>
            <el-menu-item index="/tag/carLabel/index">车辆标签</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu
          index="6"
          v-if="menuPermission.系统设置 && menuPermission.系统设置.show"
        >
          <template slot="title">
            <i
              class="el-icon-setting"
              style="margin-right: 13px; font-size: 20px"
            ></i>
            <span class="level-1">系统设置</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/user/index">用户管理</el-menu-item>
            <el-menu-item index="/role/index">角色管理</el-menu-item>
            <el-menu-item index="/auth/index">权限集</el-menu-item>
            <el-menu-item index="/share-setting">共享设置</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      activeIndex: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      menuPermission: "common/menuPermission",
    }),
  },
  watch: {
    $route(route) {
      this.activeIndex = route.meta.menuRouter || route.path;
    },
  },
  methods: {},
  mounted() {
    this.activeIndex = this.$route.meta.menuRouter || this.$route.path;
  },
};
</script>

<style lang="less">
.aside-menu {
  height: 100%;
  overflow: auto;
  .level-1 {
    font-size: 16px;
  }
  .level-2 {
    font-size: 14px;
  }
  .level-2-span {
    padding-left: 18px;
  }
  .el-menu {
    background: none;
    border: 0;
    .el-menu-item,
    .el-submenu__title {
      > i {
        margin-right: 18px;
      }
    }
    .el-menu-item-group {
      .el-menu-item-group__title {
        padding: 0;
      }
      .el-menu-item {
        padding-left: 60px !important;
      }
    }
  }
  .cdpbiMenu {
    .el-submenu__icon-arrow {
      margin-right: 0 !important;
    }
  }
}
</style>
