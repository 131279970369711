<template>
  <div id="app">
    <layout></layout>
  </div>
</template>
<script>
import Layout from "@/views/layout";
export default {
  components: {
    Layout,
  },
};
</script>
<style lang="less">
@import "./assets/font/iconfont.css";
@import "./assets/font/font-other/iconfont.css";
#app {
  height: 100%;
  color: #2c3e50;
}
</style>
