<template>
  <router-view v-if="$route.meta.aloneLayout === true"></router-view>
  <el-container v-else style="height: 100%">
    <el-header class="main-header">
      <page-header></page-header>
    </el-header>
    <el-container class="main-container">
      <el-aside style="width: 210px; height: 100%">
        <page-menu></page-menu>
      </el-aside>
      <el-container>
        <el-main>
          <div class="boxmanin">
            <router-view></router-view>
          </div>
          <div class="footer-box">
            <a
              href="https://www.mathartsys.com"
              target="_blank"
              style="color: #26262c"
            >
              <div class="footerLogo">
                <em style="margin-right: 10px">Powered by</em>
                <img src="../../assets/logo1.png" style="width: 90px" />
              </div>
              <div
                style="padding-bottom: 10px; font-size: 12px; text-align: right"
              >
                数策软件 版权所有
              </div>
            </a>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import pageMenu from "./menu";
import pageHeader from "./header";
import { mapActions, mapGetters } from "vuex";
import Cookies from "js-cookie";
import { TOKEN_KEY } from "@/common/constants";

export default {
  components: {
    pageMenu,
    pageHeader,
  },
  computed: {
    ...mapGetters({
      access_token: "auth/access_token",
    }),
  },
  watch: {
    access_token(newVal, oldVal) {
      if (!!newVal && !oldVal) {
        this.getConfig();
      }
    },
  },
  methods: {
    ...mapActions({
      getMetaData: "common/getMetaData",
      getUsersCurrentInfo: "common/getUsersCurrentInfo", // 获取用户信息（包含菜单权限）
    }),
    getConfig() {
      this.getUsersCurrentInfo();
      this.getMetaData({
        objects:
          "Customer,CustomerBehavior,Car,CarBehavior,CustomerCarRelation,MessageChannel,Flow,CampaignStatusConfig,CampaignStatusMapping,MessageTemplate,Campaign,Package,IDUpload_CID,CliquePointsAndRecharges,CliqueCardsAndVouchers,DealerPointsAndRecharges,DealerCardsAndVouchers,User,ChannelCustomerRelation,Tags",
      });
    },
  },
  mounted() {
    if (Cookies.get(TOKEN_KEY)) {
      this.getConfig();
    }
  },
};
</script>
<style lang="less" scoped>
.main-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 78px !important;
  padding: 0;
  background: #fff;
  z-index: 1;
}
.main-container {
  margin-top: 78px;
  height: calc(100% - 78px);
}

.boxmanin {
  min-height: calc(100% - 110px);
}

.footer-box {
  margin-top: 40px;
  font-size: 12px;
}
.footerLogo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
