export default {
  access_token: (state) => state.access_token,
  userInfo: (state) => {
    let userInfo = {};
    if (state.userInfo.user_name) {
      userInfo = state.userInfo;
    } else {
      try {
        userInfo = sessionStorage.getItem("userInfo");
        userInfo = JSON.parse(userInfo) || {};
      } catch (error) {
        userInfo = {};
      }
    }
    return userInfo;
  },
};
