export default {
  idUploadFields: [],
  customerFields: [],
  customerBehaFields: [],
  carFields: [],
  carBehaFields: [],
  relationFields: [],
  channelFields: [],
  userFields: [],
  flowFields: [],
  customerBehavior: [],
  carBehavior: [],
  campaignStatusConfig: [],
  campaignStatusMapping: [],
  messageTemplate: [],
  campaign: [],
  package: [],
  allChannels: [],
  typesChannels: [],
  menuPermission: {},
  userInfo: {},
  operatorMap: {
    Lookup: [
      { label: "等于", value: "equals" },
      { label: "不等于", value: "notEqual" },
      { label: "包含", value: "contains" },
      { label: "不包含", value: "notContain" },
      { label: "起始字符", value: "startsWith" },
      { label: "结束字符", value: "endsWith" },
      { label: "为空", value: "isNull" },
    ],
    Text: [
      { label: "等于", value: "equals" },
      { label: "不等于", value: "notEqual" },
      { label: "包含", value: "contains" },
      { label: "不包含", value: "notContain" },
      { label: "起始字符", value: "startsWith" },
      { label: "结束字符", value: "endsWith" },
      { label: "为空", value: "isNull" },
    ],
    Email: [
      { label: "等于", value: "equals" },
      { label: "不等于", value: "notEqual" },
      { label: "包含", value: "contains" },
      { label: "不包含", value: "notContain" },
      { label: "起始字符", value: "startsWith" },
      { label: "结束字符", value: "endsWith" },
      { label: "为空", value: "isNull" },
    ],
    MobilePhone: [
      { label: "等于", value: "equals" },
      { label: "不等于", value: "notEqual" },
      { label: "包含", value: "contains" },
      { label: "不包含", value: "notContain" },
      { label: "起始字符", value: "startsWith" },
      { label: "结束字符", value: "endsWith" },
      { label: "为空", value: "isNull" },
    ],
    WXOpenId: [
      { label: "等于", value: "equals" },
      { label: "不等于", value: "notEqual" },
      { label: "包含", value: "contains" },
      { label: "不包含", value: "notContain" },
      { label: "起始字符", value: "startsWith" },
      { label: "结束字符", value: "endsWith" },
      { label: "为空", value: "isNull" },
    ],
    Decimal: [
      { label: "等于", value: "equals" },
      { label: "不等于", value: "notEqual" },
      { label: "小于", value: "lessThan" },
      { label: "大于", value: "greaterThan" },
      { label: "小于或等于", value: "lessOrEqual" },
      { label: "大于或等于", value: "greaterOrEqual" },
      { label: "介于", value: "between" },
      { label: "为空", value: "isNull" },
    ],
    Date: [
      { label: "等于", value: "equals" },
      { label: "不等于", value: "notEqual" },
      { label: "小于", value: "lessThan" },
      { label: "大于", value: "greaterThan" },
      { label: "小于或等于", value: "lessOrEqual" },
      { label: "大于或等于", value: "greaterOrEqual" },
      { label: "介于", value: "between" },
      { label: "为空", value: "isNull" },
    ],
    DateTime: [
      { label: "等于", value: "equals" },
      { label: "不等于", value: "notEqual" },
      { label: "小于", value: "lessThan" },
      { label: "大于", value: "greaterThan" },
      { label: "小于或等于", value: "lessOrEqual" },
      { label: "大于或等于", value: "greaterOrEqual" },
      { label: "介于", value: "between" },
      { label: "为空", value: "isNull" },
    ],
    Checkbox: [
      { label: "等于", value: "equals" },
      { label: "不等于", value: "notEqual" },
      { label: "为空", value: "isNull" },
    ],
    Picklist: [
      { label: "包括", value: "includes" },
      { label: "不包括", value: "excludes" },
      { label: "为空", value: "isNull" },
    ],
    MultiPicklist: [
      { label: "包括", value: "includes" },
      { label: "不包括", value: "excludes" },
      { label: "为空", value: "isNull" },
    ],
    URL: [
      { label: "等于", value: "equals" },
      { label: "不等于", value: "notEqual" },
      { label: "包含", value: "contains" },
      { label: "不包含", value: "notContain" },
      { label: "起始字符", value: "startsWith" },
      { label: "结束字符", value: "endsWith" },
      { label: "为空", value: "isNull" },
    ],
  },
  allOperator: {
    equals: "等于",
    notEqual: "不等于",
    lessThan: "小于",
    greaterThan: "大于",
    lessOrEqual: "小于或等于",
    greaterOrEqual: "大于或等于",
    between: "介于",
    contains: "包含",
    notContain: "不包含",
    includes: "包括",
    excludes: "不包括",
    startsWith: "起始字符",
    endsWith: "结束字符",
    isNull: "为空",
  },
  queryVal: "",
  cliquePointsAndRecharges: "",
  cliqueCardsAndVouchers: "",
  dealerPointsAndRecharges: "",
  dealerCardsAndVouchers: "",
  channelCustomerRelation: "",
};
